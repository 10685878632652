.carousel-container {
  overflow: hidden;
  position: relative;
  height: 1.5%;
  background-color: #fff;
}

.carousel-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 1000px) {
    margin-right: 1rem;
  }
}

.carousel-track {
  display: flex;
  position: absolute;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 200%;
  height: 100%;
  animation: slide 20s linear infinite;

  @media (max-width: 1000px) {
    animation-name: slideMD;
  }

  @media (max-width: 600px) {
    width: 400%;
    animation-name: slideMobile;
  }
}

@keyframes slide {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@keyframes slideMD {
  0% {
    left: 0;
  }
  100% {
    left: -165%;
  }
}

@keyframes slideMobile {
  0% {
    left: 0;
  }
  100% {
    left: -325%;
  }
}
