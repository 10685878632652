.toolbar {
  font-family: "inter", sans-serif;
  background-color: #2558cd;
  color: #fff;
  box-shadow: 0px 2px 4px -1px #00000038, 0px 1px 15px 0px #00000024,
    0px 0px 1px 0px #0000001f;
}
.botao {
  font-weight: 400;
  text-align: center;
  color: #fff;
}

.entrar {
  width: 150px;
  background-color: #a159dd;
  border-radius: 100px;
  padding: 0.5rem;
}

.entrar:hover {
  background-color: #a159dd;
}
.logo-quita {
  cursor: pointer;
  max-width: 100px;
}
