.sessao-site {
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.botao-verde {
  border-radius: 20px;
  color: #2558cd;
  background-color: #e1ff7b;
  font-size: 28px;
  font-weight: 700;
  width: 350px;

  @media (max-width: 600px) {
    width: 90%;
    font-size: 18px;
  }

  &:hover {
    background-color: #e1ff7b;
  }
}
.botao-roxo {
  border-radius: 100px;
  color: #fff;
  background-color: #a159dd;
  font-size: 20px;
  font-weight: 700;
  margin-top: 2rem;

  &:hover {
    background-color: #a159dd;
  }
}

.cards {
  display: flex;
  justify-content: center;

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    margin: 1rem;
    min-height: 410px;
    border-radius: 35px 0;
    width: 400px;

    .titulo-card {
      color: #fff;
      padding: 1rem 0;
      font-size: 26px;
    }
  }
}

.deslize-mais {
  width: 100%;
  position: absolute;
  color: white;
  bottom: 10px;
  display: flex;

  .deslize-mais-text {
    display: flex;
    margin: auto;

    .text {
      font-size: 0.8rem;
      margin-top: auto;
    }

    .arrow-icon {
      font-size: 2rem;
      color: #a159dd;
      animation: arrow-animation 1s infinite;
    }
  }

  @media (max-width: 600px) {
    bottom: 0;
    top: 95%;
  }
}

.banner {
  color: #fff;
  padding: 0;
  background-image: url("../../assets/banner-mobile.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  margin: auto;

  img {
    width: 100%;
    max-width: 100%;
  }

  .texto-banner {
    @media (max-width: 1000px) {
      padding: 0 1rem 0 0;
    }

    @media (max-width: 600px) {
      margin-top: 2rem;
    }

    @keyframes arrow-animation {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(10px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  .titulo {
    font-size: 6.5rem;

    @media (max-width: 600px) {
      font-size: 4rem;
    }
  }

  .texto-secundario {
    font-size: 1.875rem;

    @media (max-width: 600px) {
      font-size: 1.875rem;
    }
  }

  @media (min-width: 1000px) {
    background-image: url("../../assets/banner.png");
  }
}

.sessao-2 {
  background-color: #2558cd;
  padding: 6rem;

  .left-items {
    .primeiro-texto {
      line-height: 66.15px;
      font-size: calc(3rem + 2px);
      color: #e1ff7b;
    }

    .descricao {
      color: #fff;
      padding-top: 1.3rem;
      font-size: 2rem;
    }

    .botao {
      display: flex;

      .botao-roxo {
        width: 90%;
      }
    }
  }

  @media (max-width: 1000px) {
    padding: 1.5rem;
  }
}
.sessao-parceiros {
  padding: 5rem 0;
}

.sessao-3 {
  padding: 5rem 0;

  .card {
    background-color: #2558cd;
  }

  .descricao {
    padding: 2rem;
    font-size: 30px;
    text-align: center;
  }
}

.sessao-4 {
  padding: 2rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .texto-sessao {
    padding: 1rem;
    font-size: 2.8rem;
    text-align: center;

    @media (min-width: 1000px) {
      width: 745px;
    }
  }

  .subtitulo {
    font-size: 30px;
  }

  .items-pagamento {
    width: 100%;
    color: #fff;
    background-color: #a159dd;
    display: flex;

    .icon {
      position: absolute;
    }

    .check {
      top: 50%;
      left: 85%;
      transform: translate(-45%, -10%);

      @media (max-width: 1000px) {
        top: 50%;
      }

      @media (max-width: 600px) {
        left: 80%;
        top: calc(575px - 40px);
        transform: translate(-10%, -10%);
      }
    }

    .asterisk {
      top: 96%;
      left: 25%;

      @media (max-width: 1000px) {
        left: 4%;
      }

      @media (max-width: 600px) {
        top: 97%;
        left: 5%;
      }
    }

    .lista-items {
      font-weight: 400;
      font-size: 26px;
    }

    @media (min-width: 600px) {
      justify-content: center;
    }
  }
}

.sessao-simulacao {
  padding: 5rem 0;
  text-align: center;

  .titulo {
    text-align: center;
    font-size: 3rem;
    color: #2558cd;

    @media (max-width: 600px) {
      font-size: 2.5rem;
    }
  }

  .subtitulo {
    font-size: 2.5rem;
    padding-bottom: 1rem;
  }

  @media (max-width: 600px) {
    padding: 3rem 0.5rem;
  }
}

.seja-parceiro {
  color: #fff;
  padding: 5rem 0;
  background-color: #2558cd;
  background-image: url("../../assets/vector-rigth.svg");
  background-repeat: no-repeat;
  background-position: right;

  .container-sessao {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .titulo-left {
      color: #e1ff7b;
      font-size: 3rem;

      @media (min-width: 1000px) {
        text-align: end;
      }
    }

    .subtitulo {
      padding: 1rem 0 0;
      font-size: 1.5rem;
    }

    .titulo-cards {
      font-size: 3rem;
      text-align: center;
      padding: 1rem 0;

      @media (min-width: 1000px) {
        width: 50%;
      }
    }

    .card {
      background-color: #e1ff7b;
      width: 390px;
      min-height: 320px;
      border-radius: 0.5rem;

      @media (max-width: 1000px) {
        width: 485px;
        min-height: 200px;
      }

      @media (max-width: 600px) {
        width: 420px;
      }

      .titulo-card {
        font-size: 2.2rem;
        color: #2558cd;
      }

      .descricao-card {
        font-size: 1.2rem;
        color: #000;
      }
    }

    .texto-rodape-sessao {
      padding: 2rem 0;
      display: flex;
      justify-content: center;

      .texto {
        font-size: 2.5rem;
        text-align: center;
        @media (min-width: 1000px) {
          width: 50%;
        }
      }
    }

    @media (max-width: 600px) {
      padding: 0 0.7rem;
    }
  }
}

.sessao-parceiro {
  align-items: center;

  .logo-parceiro {
    display: flex;
    justify-content: center;
  }

  .logo-parceiro:not(:last-child) {
    padding-bottom: 1rem;
  }
}

.sessao-depoimentos {
  text-align: justify;

  .titulo {
    text-align: center;
  }

  .depoimento {
    font-size: 20px !important;
    padding: 2rem;
  }

  .parceiro {
    text-align: center;
    color: #2558cd;
    font-size: 20px !important;
  }
}

.parceira-do-seu-negocio {
  padding: 4rem 2rem;

  .items {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .texto {
    font-size: 2rem;

    .texto-azul {
      color: #2558cd;
      font-weight: bold;
    }
  }

  .imagem-sessao {
    display: flex;
    justify-content: center;

    img {
      width: 50%;
      padding: 2rem 0;

      @media (max-width: 600px) {
        width: 80%;
      }
    }
  }

  .botao-cadastro {
    width: 80%;
  }
}

.solucoes {
  padding: 5rem;
  color: #fff;
  background-color: #2558cd;

  .titulo {
    color: #e1ff7b;
    font-size: 3rem;
  }

  .subtitulo {
    font-size: 1.5rem;
  }

  .right-item {
    display: flex;
    align-items: center;
    justify-content: center;

    .border {
      border: #e1ff7b 3px solid;
      border-radius: 15rem;
      width: 80%;

      .texto {
        padding: 1.5rem 5rem;
        font-size: 1.5rem;

        @media (max-width: 600px) {
          padding: 2rem;
          font-size: 1rem;
        }
      }

      @media (max-width: 1000px) {
        width: 100%;
      }
    }
  }

  .rodape-sessao {
    display: flex;
    justify-content: center;

    .texto-rodape-sessao {
      font-size: 2rem;
      text-align: center;
      width: 70%;
      padding: 2rem 0;

      @media (max-width: 1000px) {
        width: 100%;
      }
    }

    .botao-atendimento {
      top: 20px;
    }
  }

  @media (max-width: 1000px) {
    padding: 2rem;
  }
  @media (max-width: 600px) {
    padding: 1rem;
  }
}

.sessao-faq {
  padding: 5rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .banner-faq {
    background-image: url("../../assets/circle.svg");
    background-repeat: no-repeat;
    background-position: right top;

    @media (max-width: 1000px) {
      background-position: center top;
      background-size: cover;
    }
  }

  .left-item {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    .titulo {
      margin: 2.3rem 0 1.5rem;
      font-size: 5rem;
      text-align: right;
      color: #2558cd;

      @media (max-width: 1000px) {
        text-align: center;
        width: 50%;
      }

      @media (max-width: 600px) {
        font-size: 3rem;
        width: 100%;
      }
    }

    .subtitulo {
      margin-bottom: 1rem;
      font-size: 2rem;

      @media (max-width: 600px) {
        font-size: 1rem;
      }
    }

    @media (min-width: 1000px) {
      padding-right: 4rem;
    }

    @media (max-width: 1000px) {
      align-items: center;
    }
  }

  .right-item {
    .titulo-item-faq {
      color: #2558cd;
    }

    .item-faq {
      margin: 0.5rem;

      .descricao {
        font-size: 1rem;
      }
    }
    .item-faq:last-child {
      margin-bottom: 3rem;
    }
  }

  @media (max-width: 1000px) {
    padding: 2rem 0;
    background-position: center top;
  }
  @media (max-width: 600px) {
    padding: 2rem 0;
  }
}
