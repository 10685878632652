.quita {
  color: #2558cd;
}
.sessao-site-desenvolvedores {
  width: 100%;
  max-width: 100%;
  padding: 6rem !important;

  @media (max-width: 600px) {
    .titulo {
      font-size: 2rem !important;
    }
    .texto-secundario {
      font-size: 1.4rem !important;
    }
  }

  .img-banner {
    max-width: 85%;
    border-radius: 15px;
  }

  .titulo {
    font-size: 3rem !important;
    font-weight: bold;

    @media (max-width: 600px) {
      font-size: 2rem !important;
    }
  }
  .texto-secundario {
    font-size: 1.5rem !important;
    margin-top: 1rem;

    @media (max-width: 600px) {
      font-size: 1rem !important;
    }
  }

  .botao-documentacao {
    margin-top: 1rem;
  }

  .logo-postman {
    cursor: pointer;
    margin-top: 0.5rem;
  }

  .titulo-collections {
    font-size: 2.5rem;

    @media (max-width: 600px) {
      font-size: 2rem;
    }
  }

  @media (max-width: 1000px) {
    padding: 3rem !important;
  }

  @media (max-width: 600px) {
    padding: 2rem !important;
  }
}

.sessao-site-desenvolvedores:nth-child(odd) {
  color: #2e302e;
  background-color: #e1ff7b;
}

.sessao-site-desenvolvedores:nth-child(even) {
  color: #2e302e;
  background-color: #ffffff;
}
