.sessao-rodape {
  padding: 2rem 1rem 1rem;
  width: 100%;
  max-width: 100%;
  background-color: #2558cd;
  color: #e4faec;

  .contato {
    color: #fff;
    cursor: pointer;
    font-size: 1.1rem;
  }
  .endereco {
    font-size: 1.1rem;
  }
  .imagem {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 50%;

      @media (max-width: 1000px) {
        padding-bottom: 1rem;
      }
    }
  }
  .links {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .infos-quita {
      display: flex;
      margin-bottom: 1rem;
      color: #fff;

      .texto {
        margin: 0 0.5rem;
        cursor: pointer;
        &:hover {
          color: #e1ff7b;
        }
      }

      @media (max-width: 1000px) {
        justify-content: center;
      }
    }
  }
}

.icons-redes-sociais {
  display: flex;
  justify-content: center;

  .icon {
    color: #e1ff7b;
  }
}

@media (max-width: 600px) {
  .sessao-rodape {
    .sessao-selos {
      .selo {
        img {
          max-height: 30px !important;
        }
      }
    }
  }
}

@media (max-width: 300px) {
  .sessao-rodape {
    .sessao-selos {
      .selo {
        img {
          display: none !important;
        }
      }
    }
  }
}
